import { HashRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { MainContext } from "./context";
import { useState, Suspense } from "react";
import "./StyleMain.css";

function App() {
    const [baseProperties, setBaseProperties] = useState({
        locale: "sv",
        baseURL: "",
        tpURL: "",
        sk: "",
        country: "",
        token: ""
    });

    const [requestProperties, setRequestProperties] = useState({
        customerId: "",
        amount: "",
        clientSecret: ""
    });

    const [businessProperties, setBusinessProperties] = useState({
        redirectClientSecret: ""
    });



  return (
    <Suspense fallback="loading">
      <MainContext.Provider
        value={{
            baseProperties,
            setBaseProperties,
            requestProperties,
            setRequestProperties,
            businessProperties,
            setBusinessProperties
        }}
      >
        <HashRouter>
          <AppRouter />
        </HashRouter>

      </MainContext.Provider>
    </Suspense>
  );
}

export default App;
