import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./StylesFooter.css";
import axios from "axios";

import "./Styles.scss";
import Loader from "../../components/UI/loader/Loader";
import Header from "../../components/UI/header/Header";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

var stripePromise;

var fetchedData = {};
const xhr = new XMLHttpRequest();
xhr.open("GET", "/assets/api/v1.json", false);
xhr.send();
if (xhr.status === 200) {
  fetchedData = JSON.parse(xhr.response);
  var key =
    window.location.href.indexOf("it") > -1
      ? fetchedData._links.sk.it
      : fetchedData._links.sk.se;
  stripePromise = loadStripe(key);
}
var wasSecretRetrieved = false;

const isSwedenBackfill = window.location.href.includes("outstanding");

export default function MainPage() {
  const [clientSecret, setClientSecret] = useState("");
  const [baseProperties, setBaseProperties] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const params = useParams();
  const [error, setError] = useState("");
  const [t, i18n] = useTranslation("common");
  const timers = [];

  useEffect(() => {
    const paymentType = window.location.href.split("?type=")[1];
    setPaymentType(paymentType);

    if (!baseProperties) {
      setBaseData(fetchedData);
    }

    pollFunc(45000, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pollFunc = (timeout, interval) => {
    var startTime = new Date().getTime();
    interval = interval || 3000;
    (async function p() {
      try {
        const { data } = await axios.get(
          `${
            fetchedData._links.self.href
          }/api/v1/stripe/token/${getToken()}/payment`
        );
        setClientSecretOrRedirectToSuccessPageIfNeeded(fetchedData, data);
        setAmount(data.amount);
        timers.forEach((timer) => clearTimeout(timer));
        wasSecretRetrieved = true;
      } catch (e) {
        console.log(e);
      } finally {
        if (new Date().getTime() - startTime <= timeout) {
          if (!wasSecretRetrieved) {
            timers.push(setTimeout(p, interval));
          }
        } else {
          setError("Processing error!");
        }
      }
    })();
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  function setBaseData(data) {
    setBaseProperties({
      ...baseProperties,
      baseURL: data._links.self.href,
      tpURL: data._links.tp.href,
      country: getCountry(),
      sk: getCountry() === "se" ? data._links.sk.se : data._links.sk.it,
      token: getToken(),
      locale: getCountry() === "se" ? "sv" : "it",
    });
  }

  const getCountry = () => {
    let countryForTreatmentPlanLanding;
    let countryForTranslations;
    if (!params.token) {
      countryForTreatmentPlanLanding = "se";
      countryForTranslations = "sv";
    } else {
      countryForTreatmentPlanLanding = params.lng;
      countryForTranslations =
        countryForTreatmentPlanLanding === "se" ? "sv" : "it";
    }
    t();
    i18n.changeLanguage(countryForTranslations);
    return countryForTreatmentPlanLanding;
  };

  const getToken = () => {
    if (!params.token) {
      return params.lng;
    } else {
      return params.token;
    }
  };

  const properties = {
    ...baseProperties,
    amount,
    paymentType,
    token: getToken(),
  };

  const setClientSecretOrRedirectToSuccessPageIfNeeded = (
    baseData,
    serverResponseData
  ) => {
  
    const shouldRedirectToSuccessPage = () => {
      // Redirect to Success Page:
      // If Invoice status is "PAID", "MANUAL" or "PENDING" and is NOT SwedenBackfill
      // OR
      // If Invoice status is "PAID" or "MANUAL" and is SwedenBackfill
      return (
        (["PAID", "MANUAL", "PENDING"].includes(
          serverResponseData.invoiceStatus
        ) &&
          isSwedenBackfill === false) ||
        (["PAID", "MANUAL"].includes(serverResponseData.invoiceStatus) &&
          isSwedenBackfill === true)
      );
    };

    if (shouldRedirectToSuccessPage()) {
      let tpSuccessURL = `${
        baseData._links.tp.href
      }/${getCountry()}/treatmentplan/${getToken()}/now/success`;
      window.location.href = tpSuccessURL;
    }
    
    setClientSecret(serverResponseData.clientSecret);
  };

  return (
    <div className="payment-page-wrap-container">
      <Header />

      {!clientSecret && (
        <div className="ds-container-payment">
          {error && (
            <div className="main-container">
              <div className="errorLabel">{error}</div>
            </div>
          )}
          <div className="main-container">
            <Loader />
          </div>
        </div>
      )}

      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm {...properties} />
        </Elements>
      )}
    </div>
  );
}
