import React/*, { useContext }*/ from "react";
import "./Styles.css";
import logo from "./logo.svg";
//import { MainContext } from "../../../context";
//import { useTranslation } from "react-i18next";

const Header = () => {

  /*
  const [t, i18n] = useTranslation("common");
  const context = useContext(MainContext);

  const handleChangeLocale = (e) => {
    t();
    context.setLocale(e);
    i18n.changeLanguage(e);
  };
  */



  return (
    <div className="flexContainer">
      <div className="alignCenter">
        <img src={logo} alt="Logo" />
      </div>
      {/*<div className="alignLeft localeSpan">
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("at")}
        >
          AT
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("ch")}
        >
          CH
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("de")}
        >
          DE
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("en")}
        >
          EN
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("es")}
        >
          ES
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("fr")}
        >
          FR
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("it")}
        >
          IT
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("nl")}
        >
          NL
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("pl")}
        >
          PL
        </span>
        &nbsp;|&nbsp;
        <span
          className="localeSpan"
          _ngcontent-bdt-c37=""
          onClick={() => handleChangeLocale("sv")}
        >
          SV
        </span>
      </div>
      <div className="alignLeftMob">
        <select
          onChange={(e) => handleChangeLocale(e.target.value)}
          className="mobile-lang-selector"
        >
          <option value="at" className="mobLangOption">
            AT
          </option>
          <option value="ch" className="mobLangOption">
            CH
          </option>
          <option value="de" className="mobLangOption">
            DE
          </option>
          <option value="en" className="mobLangOption">
            EN
          </option>
          <option value="es" className="mobLangOption">
            ES
          </option>
          <option value="fr" className="mobLangOption">
            FR
          </option>
          <option value="it" className="mobLangOption">
            IT
          </option>
          <option value="nl" className="mobLangOption">
            NL
          </option>
          <option value="pl" className="mobLangOption">
            PL
          </option>
          <option value="sv" className="mobLangOption">
            SV
          </option>
        </select>
      </div>*/}
    </div>
  );
};

export default Header;
