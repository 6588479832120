import React, { useContext } from "react";
import "./Styles.css";
import { MainContext } from "../../../context";
import { useTranslation, withTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("common");
  const context = useContext(MainContext);

  return (
    <div _ngcontent-blh-c38="" className="footer">
      <a
        _ngcontent-blh-c38=""
        className="legal-link"
        href={`https://mydrsmile.com/${context.baseProperties.locale}/legal/privacy`}
      >
        {t("footer.privacy-policy")}
      </a>
      <a
        _ngcontent-blh-c38=""
        className="legal-link"
        href={`https://mydrsmile.com/${context.baseProperties.locale}/legal/terms`}
      >
        {t("footer.agb")}
      </a>
      <a
        _ngcontent-blh-c38=""
        className="legal-link"
        href={`https://mydrsmile.com/${context.baseProperties.locale}/legal/impressum`}
      >
        {t("footer.imprint")}
      </a>
      <a _ngcontent-blh-c38="" className="legal-link" href="/#">
        DrSmile © 2021
      </a>
    </div>
  );
};

export default withTranslation("common")(Footer);
