import React from "react";

const NotFound = () => {
  return (
    <div className="hd">
      {(window.location.href = "https://drsmile.de/404")}
    </div>
  );
};

export default NotFound;
