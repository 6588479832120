import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import SecurityButton from "../../components/UI/securityButton/SecurityButton";
import shield from "./shield.svg";
import stplabel from "./stplabel.svg";
import MyButton from "../../components/UI/button/MyButton";
import Spinner from "react-bootstrap/Spinner";
import percent from "./percent.svg";
import { useTranslation } from "react-i18next";
import Footer from "../../components/UI/footer/Footer";
import Loader from "../../components/UI/loader/Loader";

const isSwedenBackfill = window.location.href.includes("outstanding");

export default function CheckoutForm(props) {
  const { t } = useTranslation("common");
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [instalmentRequestIsInProgress, setInstalmentRequestIsInProgress] =
    useState(false);
  const [instalmentError, setInstalmentError] = useState("");
  const [paymentType, setPaymentType] = useState("card");
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [waitingForPaymentIntent, setWaitingForPaymentIntent] = useState(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      setWaitingForPaymentIntent(false);
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          success();
          break;
        default:
          if (props.paymentType === "klarna") {
            setError(t("klarna_payment_error"));
          } else {
            setError(paymentIntent.last_payment_error);
          }
          setPaymentFailed(true);
          setWaitingForPaymentIntent(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.href}?type=${paymentType}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error.decline_code === "insufficient_funds") {
      setMessage(t("insufficient_funds"));
    } else if (
      error.type === "card_error" ||
      error.type === "validation_error" ||
      error.type === "invalid_request_error"
    ) {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  const getCurrency = (locale) => {
    const currencyMap = {
      sv: " SEK",
      en: "",
      it: " \u20AC",
    };
    return currencyMap[locale];
  };

  const handleChange = (event) => {
    if (event.complete) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (event.value && event.value.type !== "card") {
      setPaymentType("klarna");
    } else {
      setPaymentType("card");
    }
  };
  
  const success = () => {
    let tpSuccessURL = `${props.tpURL}/${props.country}/treatmentplan/${props.token}/now/success`;
    window.location.href = tpSuccessURL;
  };

  const handleInstalmentSubmit = async (event) => {
    event.preventDefault();
    setInstalmentRequestIsInProgress(true);
    try {
      const regularInstalmentOption = "instalment";
      const downPaymentOption = "downpayment";

      // Option should be "instalment" unless the url contains "outstanding", then it is "downpayment" for the Klarna backfill
      const option = isSwedenBackfill
        ? downPaymentOption
        : regularInstalmentOption;
      
      const res = await fetch(
        `${props.baseURL}/api/v1/stripe/token/${props.token}/payment/${option}`,
        {
          method: "POST",
        }
      );
      if (res.status === 202) {
        success();
      } else {
        setInstalmentRequestIsInProgress(false);
        setInstalmentError("Problem with payment processing!");
        setError("");
      }
    } catch (e) {
      setInstalmentRequestIsInProgress(false);
      setInstalmentError("Problem with payment processing!");
      setError("");
    }
  };

  return (
    <div className="formAndFooterContainer">
      {waitingForPaymentIntent ? (
        <div className="ds-container">
          <div className="main-container">
            <Loader />
          </div>
        </div>
      ) : (
        <div className="ds-container">
          <div>
            <label className="header-label">{t("confirm order")}</label>
            <div className="title-blurb">
              <span>{t("header.Confirm_your_order_blurb_1_prt")}</span>
              <span className="bl">
                {" " + props.amount + getCurrency(props.locale)}
              </span>
              <span>{". "}</span>
              <span>{t("header.Confirm_your_order_blurb_2_prt")}</span>
            </div>
          </div>
          {message && (
            <div className="main-container">
              <div className="errorLabel">{message}</div>
            </div>
          )}
          {error && (
            <div className="main-container">
              <div className="errorLabel">{error}</div>
            </div>
          )}
          {/*{stripeError && (*/}
          {/*    <div className="main-container">*/}
          {/*      <div className="errorLabel">{stripeError}</div>*/}
          {/*    </div>*/}
          {/*)}*/}
          <div className="main-container">
            <form className="checkout-form" onSubmit={handleSubmit}>
              <SecurityButton
                icon={shield}
                text="pay-now.title"
                altText="shield"
                height="25"
              />
              <PaymentElement
                onChange={handleChange}
                //options={CARD_ELEMENT_OPTIONS}
              />
              <div className="button-container">
                <div className="stp-label">
                  <img
                    src={stplabel}
                    alt="powered by stripe"
                    height="37"
                    width="174"
                  />
                </div>
                <MyButton
                  cl="my-btn"
                  disabled={
                    disabled || isLoading || instalmentRequestIsInProgress
                  }
                  type="submit"
                >
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="light"
                    />
                  )}
                  {t("Pay now")}
                </MyButton>
              </div>
            </form>
          </div>
          {/*<label className="center-label-big">{t("or")}</label>*/}
          {instalmentError && (
            <div className="main-container">
              <div className="errorLabel">{t("backend error")}</div>
            </div>
          )}

          {(props.locale === "it" ||
            (props.locale === "sv" && isSwedenBackfill === false)) && (
            <div className="main-container">
              <form className="checkout-form" onSubmit={handleInstalmentSubmit}>
                <SecurityButton
                  icon={percent}
                  text="pay by instalments"
                  altText="shield"
                  height="25"
                />
                <div
                  className="instalment-title"
                  dangerouslySetInnerHTML={{
                    __html: t("Payment by instalments description", {
                      amount: props.amount,
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></div>
                <div className="button-container">
                  <MyButton
                    cl="big-btn my-btn"
                    type="submit"
                    disabled={
                      !stripe || instalmentRequestIsInProgress || isLoading
                    }
                  >
                    {instalmentRequestIsInProgress && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="light"
                      />
                    )}
                    {t("Instalments button")}
                  </MyButton>
                </div>
              </form>
            </div>
          )}
          {/* Only show downpayment option if it is a Swedish backfill and Klarna has failed */}
          {props.locale === "sv" && isSwedenBackfill === true && paymentFailed && (
            <div className="main-container">
              <form className="checkout-form" onSubmit={handleInstalmentSubmit}>
                <SecurityButton
                  icon={percent}
                  text="pay by downpayment"
                  altText="shield"
                  height="25"
                />
                <div
                  className="instalment-title"
                  dangerouslySetInnerHTML={{
                    __html: t("Payment by downpayment description", {
                      amount: props.amount,
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></div>
                <div className="button-container">
                  <MyButton
                    cl="big-btn my-btn"
                    type="submit"
                    disabled={
                      !stripe || instalmentRequestIsInProgress || isLoading
                    }
                  >
                    {instalmentRequestIsInProgress && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="light"
                      />
                    )}
                    {t("Instalments button")}
                  </MyButton>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}
