import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import "./Styles.css";

const SecurityButton = (props) => {
  const { t } = useTranslation("common");
  return (
    <div className="security-button">
      <img src={props.icon} alt={props.altText} height={props.height} />
      <span className="security-button-text">{t(props.text)}</span>
    </div>
  );
};

export default withTranslation("common")(SecurityButton);
